import * as React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import ButtonLink from "../components/button-link"
import PageTitle from "../components/page-title"
import Seo from "../components/seo"

const CasesPage = () => {
  return (
    <>
      {/* SEO */}
      <Seo seo={{ metaTitle: '404 Niet gevonden' }}/>
      
      {/* Navigation */}
      <Header/>

      {/* Page Title */}
      <PageTitle data={{
        title: '404',
        description: 'Sorry, er ging iets mis. Deze pagina is niet gevonden.',
        state: 'error'
      }}/>

      <section className="container mx-auto py-16">
        <p className="mb-4">De pagina is misschien verplaats of tijdelijk onbereikbaar.</p>
        <ButtonLink to="/">Terug naar Home</ButtonLink>
      </section>

      {/* Footer */}
      <Footer/>
    </>
  )
}

export default CasesPage